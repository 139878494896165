import { Component } from "../componant";
import { MenuComponent } from "./menu-component";
import { TransportTabContentComponent } from "./transport-tab-content-component";
import { NoBookmarkTabContentComponent } from "./no-bookmark-tab-content-component";
import { BookmarkClientFactory } from "../../client/bookmark-client-factory";
import { callApiJourneyFavorite, callApiLinesById } from "../../api";
import {addSimplebarEventListener} from "../../simplebar";
import {getAccessibilityEnterAwareClassname} from "bookmark_bundle/subscriber/accessibility-subscriber";

export function JourneyTabContentComponent(props) {
  Component.call(this, props);
  this.bookmarkCollections = [];
  this.classname = "JourneyTabContentComponent";

}

JourneyTabContentComponent.prototype = Object.create(Component.prototype);

JourneyTabContentComponent.prototype.render = function () {
  const container = document.createElement("div");
  container.classList.add("journey-tab-content");

  let client = new BookmarkClientFactory().createClient();
  this.bookmarkCollections = client.getSync();

  this.bookmarkCollections = this.bookmarkCollections.filter((bookmark) => {
    return bookmark.type.includes("journey_solution");
  });

  if (this.bookmarkCollections.length === 0) {
    const noBookmarkTabContent = new NoBookmarkTabContentComponent();
    noBookmarkTabContent.mount(container);
    return container;
  }

  const journeySection = this.renderJourneySection();

  container.appendChild(journeySection);
  addSimplebarEventListener(this.classname);
  return container;
};

JourneyTabContentComponent.prototype.renderJourneySection = function () {
  const section = document.createElement("section");
  section.classList.add("section-journey");

  const container = document.createElement("div");
  container.classList.add("journey-container");

  this.bookmarkCollections.forEach((bookmark) => {
    const journey1 = this.renderJourneyItem({
      id: bookmark.id,
      fromAdress: bookmark.data.from,
      fromCoord: bookmark.data.fromCoord,
      toAdress: bookmark.data.to,
      toCoord: bookmark.data.toCoord,
      linkHref: bookmark.data.linkHref,
      time: 15,
    });
    container.appendChild(journey1);
  });
  // First item of the Journey section

  // ...

  section.appendChild(container);

  return section;
};

JourneyTabContentComponent.prototype.renderJourneyItem = function (journeyData) {
  const journeyItem = document.createElement("div");
  journeyItem.classList.add("journey-item", "shared-item", "deletable", getAccessibilityEnterAwareClassname());
  journeyItem.setAttribute("title", Translator.trans("bookmark.journey.select"));
  journeyItem.setAttribute("aria-label", Translator.trans("bookmark.journey.select"));
  journeyItem.setAttribute("role", "link");
  journeyItem.setAttribute("tabindex", 0);

  const transportSections = document.createElement("div");
  transportSections.classList.add("sections");

  const loaderDiv = document.createElement("div");
  loaderDiv.classList.add("loader-content");

  const journeyContent = document.createElement("div");
  journeyContent.classList.add("content", "no-margin-top");

  let loadingImg = this.getLoadingElement();
  loaderDiv.appendChild(loadingImg);
  transportSections.appendChild(loaderDiv);

  callApiJourneyFavorite(journeyData.linkHref).then((result) => {
    transportSections.removeChild(loaderDiv);

    let callback = (event) => {
      console.log(journeyData);
      console.log(".??????????????????????,,");
      return this.onClickSection(
        event,
        journeyData.fromAdress,
        journeyData.toAdress,
        btoa(journeyData.linkHref),
        journeyData.fromCoord,
        journeyData.toCoord,
      );
    };
    let transportSectionContentHtml =
      result.success === true
        ? result.html
        : Translator.trans("bookmark.journey.no_solution");
    transportSections.insertAdjacentHTML(
      "beforeend",
      transportSectionContentHtml
    ); // append the HTML string to the div element
    journeyItem.addEventListener("click", callback);

    // time
    const journeyTime = document.createElement("div");
    journeyTime.classList.add("journey-time", "right");
    journeyTime.innerHTML = `${Translator.trans("bookmark.approximately")} <br><span>${this.formatTimeDuration(result.minute)}</span>`;
    journeyContent.appendChild(journeyTime);
  });

  const fromMarker = document.createElement("img");
  fromMarker.classList.add("marker-from");
  fromMarker.alt = "";
  fromMarker.src = `/bundles/canaltppnpcore/images/from.png`;

  const journeyFrom = document.createElement("div");
  journeyFrom.classList.add("journey-from");
  journeyFrom.textContent = journeyData.fromAdress;
  journeyFrom.insertAdjacentElement("afterbegin", fromMarker);

  const toMarker = document.createElement("img");
  toMarker.alt = "";
  toMarker.classList.add("marker-to");
  toMarker.src = `/bundles/canaltppnpcore/images/to.png`;

  const journeyTo = document.createElement("div");
  journeyTo.classList.add("journey-to");
  journeyTo.textContent = journeyData.toAdress;
  journeyTo.insertAdjacentElement("afterbegin", toMarker);

  // Création du menu
  const menu = new MenuComponent({
    id: journeyData.id,
    componentUpdate: this.update.bind(this),
  }).render();
  menu.classList.add("no-header");
  journeyContent.appendChild(menu);

  journeyItem.appendChild(journeyContent);
  const journeyDisplay = document.createElement("div");
  journeyDisplay.classList.add("left", "journey-display");

  // other content
  journeyDisplay.appendChild(journeyFrom);
  journeyDisplay.appendChild(transportSections);
  journeyDisplay.appendChild(journeyTo);
  journeyContent.appendChild(journeyDisplay);

  return journeyItem;
};

JourneyTabContentComponent.prototype.formatTimeDuration = function (minutes) {
  if (typeof minutes !== "number" || isNaN(minutes)) {
    return '';
  }

  if (minutes < 60) {
    return `${minutes}<br> min`;
  } else {
    let hours = Math.floor(minutes / 60);
    let remainingMinutes = minutes % 60;
    return `${hours} h<br> ${remainingMinutes} min`;
  }
};

JourneyTabContentComponent.prototype.onClickSection = function (
  event,
  nameFrom,
  nameTo,
  link,
  fromCord,
  toCoord,
) {
  const now = new Date();

  const formattedDate = `${now.getDate().toString().padStart(2, "0")}/${(
    now.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}/${now.getFullYear()}`;
  const formattedTime = `${now.getHours().toString().padStart(2, "0")}:${now
    .getMinutes()
    .toString()
    .padStart(2, "0")}`;

  let url = `journey/result/?search[from][autocomplete]=${nameFrom}&search[from][autocomplete-hidden]=${fromCord}&search[to][autocomplete]=${nameTo}&search[to][autocomplete-hidden]=${toCoord}&search[datetime_represents]=departure&search[datetime][date]=${formattedDate}&search[datetime][time]=${formattedTime}&search[modes][]=physical_mode:Metro&search[modes][]=physical_mode:Bus;physical_mode:Shuttle&search[modes][]=physical_mode:Tramway&search[modes][]=physical_mode:Train;physical_mode:RapidTransit;physical_mode:LongDistanceTrain;physical_mode:LocalTrain;physical_mode:RailShuttle&search[modes][]=physical_mode:Funicular&search[drawdown_modes][]=car&search[drawdown_modes][]=bike&search[self_service_modes][]=bss&search[bike_speed][]=standard&search[walking_speed][]=standard&schedule[stop_area_lines][autocomplete]=&schedule[stop_area_lines][autocomplete-hidden]=`
  
  if (Kisio.type_product === "standalone") {
    url = `result/?search[from][autocomplete]=${nameFrom}&search[from][autocomplete-hidden]=${fromCord}&search[to][autocomplete]=${nameTo}&search[to][autocomplete-hidden]=${toCoord}&search[datetime_represents]=departure&search[datetime][date]=${formattedDate}&search[datetime][time]=${formattedTime}&search[modes][]=physical_mode:Metro&search[modes][]=physical_mode:Bus;physical_mode:Shuttle&search[modes][]=physical_mode:Tramway&search[modes][]=physical_mode:Train;physical_mode:RapidTransit;physical_mode:LongDistanceTrain;physical_mode:LocalTrain;physical_mode:RailShuttle&search[modes][]=physical_mode:Funicular&search[drawdown_modes][]=car&search[drawdown_modes][]=bike&search[self_service_modes][]=bss&search[bike_speed][]=standard&search[walking_speed][]=standard&schedule[stop_area_lines][autocomplete]=&schedule[stop_area_lines][autocomplete-hidden]=`  
  }
  
  url += `&link=${link}`;

  window.location.replace(url);
};

JourneyTabContentComponent.prototype.getModePictoSrc = function (commercialMode) {
  let mode = commercialMode.toLowerCase();
  let src = window.Kisio.modes_img[mode];
  if (typeof window.Kisio.modes_img[mode] === "undefined") {
    src = window.Kisio.modes_img["commercial_mode:" + mode];
  }
  return src;
};

JourneyTabContentComponent.prototype.getLoadingElement = function () {
  const loadingImg = document.createElement("img");
  loadingImg.classList.add("loader");
  loadingImg.src = `/bundles/canaltppnpcore/images/picto/16/loading.gif`;
  loadingImg.alt = "loading...";
  loadingImg.style.width = "20px";
  return loadingImg;
};
